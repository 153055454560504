import { atom, useAtom } from 'jotai';
import {
  brandAtom,
  modelAtom,
  versionAtom,
} from '../hooks/use-picker-selection';

export const vehiclePickerOpen = atom<boolean>(false);
export const unsavedInformationAtom = atom<boolean>(false);

export const openVehiclePickerToggle = atom(null, (get, set, by) =>
  set(vehiclePickerOpen, (value) => !value)
);

export const settingsModalOpen = atom<boolean>(false);

export const settingsModalToggle = atom(null, (get, set, by) =>
  set(settingsModalOpen, (value) => !value)
);

export const vehiclePickerAtom = atom<Array<number>>([]);

export const vehiclePickerPhase = atom<
  'Model' | 'Version' | 'Brand' | 'AllSelected' | null
>(null);

export const plateOrKbaForm = atom<boolean>(true);

export const categoryInformation = atom<TCategorySelection | null>(null);

export const selectVehicleStepAtom = atom<
  | null
  | 'SELECT_SAVED_CAR'
  | 'SELECT_BRAND'
  | 'SELECT_MODEL_UNSAVED'
  | 'SELECT_MODEL'
  | 'SELECT_VERSION'
  | 'SELECT_PLATE_OR_KBA'
  | 'SELECT_PART'
  | 'SUBMIT_SELECTION'
>(null);

export const currentSavedCarAtom = atom<any>(null);
export const allSavedCarsAtom = atom<any>(null);

export const inputFocusedAtom = atom<boolean>(false);
export const lastHeightAtom = atom<number | undefined>(undefined);
export const lastPaddingAtom = atom<number | undefined>(undefined);
